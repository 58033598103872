
























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProteinType } from '@/modules/common'
import ProteinButton from '@/components/common/ProteinButton/index.vue'

@Component({ components: { ProteinButton } })
export default class CustomProteinSelector extends Vue {
  @Prop()
  private startOverRoute!: string | undefined;

  selectedProteins: Array<ProteinType> = [];

  proteinCSS(proteinType: ProteinType): string {
    const index = this.selectedProteins.indexOf(proteinType)
    if (index > -1) {
      return 'selected'
    }
    return 'unselected'
  }

  toggle(proteinType: ProteinType) {
    const index = this.selectedProteins.indexOf(proteinType)
    if (index === -1) {
      this.selectedProteins.push(proteinType)
    } else {
      this.selectedProteins.splice(index, 1)
    }
  }

  customize() {
    this.$emit('proteins-selected', this.selectedProteins)
  }
}
