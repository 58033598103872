
































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { SubstituteItem } from '@/modules/common'

@Component
export default class ItemReplacementCard extends Vue {
  @Prop()
  private ItemIndex!: number

  @Prop()
  private ItemToSwap!: string

  @Prop({ type: Object as () => SubstituteItem })
  private SubstituteItem!: SubstituteItem

  get thumbnail() {
    return `https://assets.steakchop.com/product-thumbnails/${this.SubstituteItem.id}.jpg`
  }

  get imageHeight() {
    let height = 0

    if (this.$vuetify.breakpoint.smAndDown) {
      return null
    }
    height = 160

    return `${height}px`
  }

  get surcharge() {
    if (this.SubstituteItem && this.SubstituteItem.surcharge) {
      return this.SubstituteItem.surcharge
    }
    return 0
  }

  get imageWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return null
    }
    return `${(this.$vuetify.breakpoint.width * 0.95) / 2}px`
  }

  private swap(id: string) {
    this.$emit('substitute-item', { itemIndex: this.ItemIndex, newItemId: id, surcharge: this.surcharge })
  }
}
