























import { Component, Vue, Prop } from 'vue-property-decorator'
import ItemReplacementCard from '@/components/common/ItemSelectorList/ItemReplacementCard/index.vue'
import { PackItem, ProteinType, SubstituteItem } from '@/modules/common'

@Component({ components: { ItemReplacementCard } })
export default class ItemSwapperList extends Vue {
  @Prop({ type: Object as () => PackItem })
  private PackItem!: PackItem

  @Prop()
  private ItemIndex!: number

  itemToSwapId = ''

  @Prop()
  private proteinType!: ProteinType | undefined

  get proteinToFilter() {
    if (this.proteinType === ProteinType.Unknown || this.proteinType === undefined) {
      if (this.PackItem && this.PackItem.item) {
        return this.PackItem.item.proteinType
      }
    }
    return this.proteinType
  }

  get SubstituteItems(): Array<SubstituteItem> {
    if (this.ItemIndex > -1) {
      return this.PackItem.subsituteItems.filter(
        (p) => p.id !== this.PackItem.id && p.item && p.item!.proteinType === this.proteinToFilter
      )
    }
    return []
  }

  substituteItem(input: { itemIndex: number; newItemId: string; surcharge: number }) {
    if (this.$vuetify.breakpoint.xs) {
      if (this.itemToSwapId === input.newItemId) {
        this.itemToSwapId = ''
        this.$emit('pre-substitute-item', { itemIndex: -1, newItemId: '' })
      } else {
        this.itemToSwapId = input.newItemId
        this.$emit('pre-substitute-item', input)
      }
    } else {
      this.$emit('substitute-item', input)
    }
  }
}
