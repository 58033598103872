import { ByoConfiguration, ProductConfigurationTemplate } from '@/modules/byo'
import {
  CommonItemConfiguration,
  Contact,
  LegacyPackItem,
  PackSize,
  ProteinType,
  ShippingCenter,
  SubscriberStatus,
  SubscriptionConfiguration
} from '@/modules/common'
import axios from 'axios'

export async function GetQuickAccessBYOConfiguration(input: {
  contactId: string
}): Promise<{
  freeItem: LegacyPackItem
  nextPack: CommonItemConfiguration
  packItems: Array<LegacyPackItem>
  contact: Contact
  center: number
  subscriptionConfiguration: SubscriptionConfiguration
  subscriptionDetails: {
    subscriberStatus: SubscriberStatus
    discount: number
    listPrice: number
    hasProcessedOrder: boolean
    hasPendingOrder: boolean
    promotionText: string
    nextShipmentDate: string
  }
}> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = await axios.post<any>('/api/v0/quick-access/byo', input)
  return result.data
}

export async function GetQuickAccessBYOFeautedPacks(): Promise<Array<CommonItemConfiguration>> {
  const result = await axios.get<any>('/api/v0/quick-access/byo/featured-packs')
  return result.data
}

export async function GetQuickAccessBYOSubsituteItems(input: {
  center: number
  proteinFilters: Array<ProteinType>
  items?: Array<number>
  rangeItems?: Array<{ displayOrder: number; maxSwapPrice: number }>
  restrictToEqualValue: boolean
}) {
  if (input.items) {
    const result = await axios.post<any>('/api/v0/quick-access/byo/get-subsitutes', input)
    return result.data
  } else {
    const result = await axios.post<any>('/api/v0/quick-access/byo/get-subsitutes_new', input)
    return result.data
  }
}

export async function GetQuickAccessBYOCustomPackItems(input: { selectedProteins: Array<ProteinType> }) {
  const list = input.selectedProteins.join('')
  const result = await axios.get<any>(`/api/v0/quick-access/byo/get-custom-item-configuration/${list}`)
  return result.data
}

export async function UpdatePack(input: {
  contactId: string
  packSize: PackSize
  items: Array<LegacyPackItem>
  proteins: Array<ProteinType>
}): Promise<{
  freeItem: LegacyPackItem
  nextPack: CommonItemConfiguration
  packItems: Array<LegacyPackItem>
  contact: Contact
  subscriptionConfiguration: SubscriptionConfiguration
  subscriptionDetails: {
    subscriberStatus: SubscriberStatus
    discount: number
    listPrice: number
    hasProcessedOrder: boolean
    hasPendingOrder: boolean
    promotionText: string
    nextShipmentDate: string
  }
}> {
  const result = await axios.post<any>('/api/v0/quick-access/byo/update-next-pack', {
    contactId: input.contactId,
    packSize: input.packSize,
    proteins: input.proteins,
    items: input.items.map(p => ({
      itemId: Number.parseInt(p.id, 10),
      shippingQuantity: p.shippingQuantity,
      quantity: p.item?.quantity,
      displayOrder: p.displayOrder,
      item: p.item,
      productConfigurationId: 0
    }))
  })
  return result.data
}

export async function GetCurrentTemplate(input: { contactId: string }) {
  const result = await axios.get<ByoConfiguration>(`/api/v0/quick-access/byo_new/${input.contactId}`)
  return result.data
}

export async function UpdateTemplate(input: {
  fulfillmentCenterId: ShippingCenter
  template: ProductConfigurationTemplate
}) {
  const result = await axios.post<ByoConfiguration>('/api/v0/quick-access/byo_new', input)
  return result.data
}
