import { Item, PackItem, PackSize, SubscriptionStatus } from '../common'

export interface PricingDetails {
  listPrice: number
  discount: number
  surcharge: number
}

export interface ShippingDetails {
  fulfillmentCenterId: number
  isInProgress: boolean
  shipmentWeek: string
  trackingNumber: null
}

export interface SubscriptionDetails {
  subscriptionId: string
  subscriptionStatus: SubscriptionStatus
  packSize: number
  frequency: number
  proteinTypes: number[]
  contactId: string
  firstName: string
  lastName: string
}

export enum ItemTemplateType {
  Unknown = 0,
  StandardReoccuring = 1,
  StandardOnetime = 2,
  FreeReoccuring = 3,
  FreeOnetime = 4
}

export enum ProductConfigurationTemplateType {
  Unknown = 0,
  Unboxed = 1,
  Standard = 2,
  CustomCurated = 3
}

export interface ProductConfigurationTemplateItem extends PackItem {
  productConfigurationTemplateId: string
  itemId: number
  item: Item | undefined
  itemTemplateTypeId: ItemTemplateType
  referenceItemPrice: number
  surcharge: number
  quantity: number
  shippingQuantity: number
  displayOrder: number
  createdDate: Date
  updatedDate: Date
}

export interface ProductConfigurationTemplate {
  productConfigurationTemplateId: string
  productConfigurationTemplateType: ProductConfigurationTemplateType
  contactId: string
  templateName: string
  packSize: PackSize
  maximumProductPrice: number
  surcharge: number
  isActiveTemplate: boolean
  items: ProductConfigurationTemplateItem[]
  createdDate: Date
  updatedDate: Date
}

export interface ByoConfiguration {
  activeTemplate: ProductConfigurationTemplate
  subscriptionDetails: SubscriptionDetails
  pricingDetails: PricingDetails
  shipmentDetails: ShippingDetails
}
