import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import store from '@/store'
import * as api from '@/apis/quick-access'
import { GetItems } from '@/apis/signup'
import {
  PackSize,
  Item,
  LegacyPackItem,
  ProteinType,
  CommonItemConfiguration,
  SubscriptionStatus,
  ShippingCenter,
  ItemTemplateConfiguration,
  SubstituteItem
} from '@/modules/common'
import { isNumber, min, orderBy, sortBy, sum, uniq } from 'lodash'

import { QuickAccessData } from './types'
import {
  ProductConfigurationTemplate,
  SubscriptionDetails,
  PricingDetails,
  ShippingDetails,
  ProductConfigurationTemplateType,
  ProductConfigurationTemplateItem,
  ItemTemplateType
} from '@/modules/byo'

export function rounder(value: number, step: number) {
  step || (step = 1.0)
  const inv = 1.0 / step
  return Math.round(value * inv) / inv
}

@Module({ dynamic: true, store, name: 'quick-access' })
export default class QuickAccessModule extends VuexModule implements QuickAccessData {
  activeConfiguration: ItemTemplateConfiguration = {
    id: '',
    shippingCenter: ShippingCenter.Unknown,
    name: '',
    description: '',
    activeTemplate: {
      productConfigurationTemplateId: '',
      productConfigurationTemplateType: ProductConfigurationTemplateType.Unknown,
      contactId: '',
      templateName: '',
      packSize: PackSize.Unknown,
      maximumProductPrice: 0,
      surcharge: 0,
      isActiveTemplate: false,
      items: [],
      createdDate: new Date('1/1/1900'),
      updatedDate: new Date('1/1/1900')
    },
    isReadonly: false,
    discount: 0,
    surcharge: 0,
    listPrice: 0
  }

  subscriptionDetails: SubscriptionDetails = {
    subscriptionId: '',
    subscriptionStatus: SubscriptionStatus.Unknown,
    packSize: 0,
    frequency: 0,
    proteinTypes: [],
    contactId: '',
    firstName: '',
    lastName: ''
  }

  pricingDetails: PricingDetails = {
    listPrice: 0,
    discount: 0,
    surcharge: 0
  }

  shipmentDetails: ShippingDetails = {
    fulfillmentCenterId: 0,
    isInProgress: false,
    shipmentWeek: '',
    trackingNumber: null
  }

  featuredPacks: Array<ItemTemplateConfiguration> = []

  packName = ''

  newPackSize = PackSize.Unknown

  items: Array<Item> = []

  unboxed: boolean | undefined

  editingUnboxed = false

  unboxedItems: Array<ProductConfigurationTemplateItem> = []

  get unboxedAddItems() {
    if (this.isUnboxed) {
      const items = sortBy(
        this.items
          .filter(
            p =>
              this.unboxedItems.find(q => q.id === p.id) === undefined &&
              p.itemAvailabilities.find(
                r => r.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId && r.isAvailable
              )
          )
          .map(p => ({
            productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
            itemId: parseInt(p.id),
            item: p,
            itemTemplateTypeId: ItemTemplateType.StandardReoccuring,
            referenceItemPrice: p.itemAvailabilities.find(
              q => q.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId
            )!.price,
            surcharge: 0,
            quantity: p.quantity,
            shippingQuantity: 0,
            displayOrder:
              p.itemAvailabilities.find(q => q.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId)!
                .price * -1,
            createdDate: new Date(Date.now()),
            updatedDate: new Date(Date.now()),
            id: p.id,
            subsituteItems: new Array<SubstituteItem>()
          })),
        z => z.displayOrder
      )

      let displayOrder = 1000

      items.forEach(p => {
        p.displayOrder = displayOrder++
      })

      return items
    }
    return []
  }

  get currentTemplateSurcharge() {
    return this.activeConfiguration.activeTemplate.surcharge
  }

  get currentConfigurationSurcharge() {
    if (isNumber(this.totalUnboxedSurcharge)) {
      return this.totalUnboxedSurcharge
    }
    return 0
  }

  get roundedItemPrice() {
    return (id: string) =>
      rounder(
        this.items
          .find(p => p.id === id)!
          .itemAvailabilities.find(p => p.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId)!.price *
          1.15,
        0.25
      )
  }

  get standardItemPrice() {
    return (id: string) =>
      this.items
        .find(p => p.id === id)!
        .itemAvailabilities.find(p => p.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId)!.price
  }

  get isUnboxed() {
    if (this.unboxed !== undefined) {
      return this.unboxed
    }
    return (
      this.activeConfiguration.activeTemplate.productConfigurationTemplateType ===
      ProductConfigurationTemplateType.Unboxed
    )
  }

  get totalUnboxedPaidProductPrice() {
    return sum(
      this.unboxedItems.map(p =>
        p.item &&
        p.item.itemAvailabilities &&
        (p.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
          p.itemTemplateTypeId === ItemTemplateType.StandardReoccuring)
          ? p.shippingQuantity *
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            p.item.itemAvailabilities.find(q => q.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId)!
              .price
          : 0
      )
    )
  }

  get unboxedProgress() {
    let progress = this.totalUnboxedPaidProductPrice / this.activeConfiguration.activeTemplate.maximumProductPrice
    progress = progress * 100
    return progress
  }

  get moneyLeft() {
    return this.activeConfiguration.activeTemplate.maximumProductPrice - this.totalUnboxedPaidProductPrice
  }

  get unboxedProgessMessage() {
    if (this.totalUnboxedPaidProductPrice === 0) {
      return 'Get going, add some delicious products by clicking that green + circle!'
    }

    if (this.moneyLeft > 0) {
      const tooExpensiveCount = this.items.filter(
        p =>
          ((this.customizedPackProteins.indexOf(p.proteinType) !== -1 &&
            p.itemAvailabilities.find(
              q => q.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId && q.isAvailable
            )?.price) ??
            999) > this.moneyLeft
      ).length
      if (tooExpensiveCount === 0) {
        return 'You have plenty of room, keep on adding, don\'t be shy!'
      } else {
        const totalItems = this.items.filter(p => this.customizedPackProteins.indexOf(p.proteinType) !== -1).length
        if (tooExpensiveCount === totalItems) {
          return 'Any more items you add will have an extra charge.'
        }
        if (tooExpensiveCount / totalItems <= 0.25) {
          return 'You still have a lot of room, keep on adding!'
        }
        if (tooExpensiveCount / totalItems <= 0.5) {
          return 'You still have room, keep on adding!'
        }
        if (tooExpensiveCount / totalItems <= 0.75) {
          return 'You still have a some more room, keep on adding!'
        } else {
          return 'You still have a bit more room, keep on adding!'
        }
      }
    } else {
      return `This tasty pack costs $${this.nextPackPurchasePrice.toFixed(2)}`
    }
  }

  get totalUnboxedSurcharge() {
    const overrage = rounder(
      (this.totalUnboxedPaidProductPrice - this.activeConfiguration.activeTemplate.maximumProductPrice) * 1.055,
      0.25
    )
    if (overrage > 0) {
      return overrage
    }
    return 0
  }

  get hasPendingOrder() {
    return this.shipmentDetails.isInProgress
  }

  get frequency() {
    return this.subscriptionDetails.frequency
  }

  get customizedPackProteins() {
    if (this.editingUnboxed) {
      return uniq(this.items.map(p => p.proteinType))
    }
    return uniq(
      this.activeConfiguration.activeTemplate.items
        .filter(
          p =>
            p.itemTemplateTypeId !== ItemTemplateType.FreeReoccuring &&
            p.itemTemplateTypeId !== ItemTemplateType.FreeOnetime
        )
        .map(p => p.item?.proteinType)
    )
  }

  get discount() {
    return this.pricingDetails.discount
  }

  get listPrice() {
    return this.pricingDetails.listPrice
  }

  get surcharge() {
    return this.pricingDetails.surcharge
  }

  get packSize() {
    return this.subscriptionDetails.packSize
  }

  get firstName() {
    return this.subscriptionDetails.firstName
  }

  get nextPack() {
    return this.subscriptionDetails.subscriptionStatus === SubscriptionStatus.Active
      ? this.shipmentDetails.shipmentWeek
      : 'None Selected'
  }

  get nextPackPurchasePrice() {
    return this.pricingDetails.listPrice + this.totalUnboxedSurcharge - this.pricingDetails.discount
  }

  get hasActiveSubscription() {
    return this.subscriptionDetails.subscriptionStatus === SubscriptionStatus.Active
  }

  get hasSuspendedSubscription() {
    return this.subscriptionDetails.subscriptionStatus === SubscriptionStatus.Suspended
  }

  get hasCanceledubscription() {
    return this.subscriptionDetails.subscriptionStatus === SubscriptionStatus.Canceled
  }

  get hasPausedSubscription() {
    return this.subscriptionDetails.subscriptionStatus === SubscriptionStatus.Canceled
  }

  get descriptionText(): string {
    switch (this.activeConfiguration.activeTemplate.productConfigurationTemplateType) {
      case ProductConfigurationTemplateType.CustomCurated:
        return 'This pack has been currated specifically for you. If you want to make any changes please email or call us!'
      default:
        return ''
    }
  }

  get freeItems() {
    return this.activeConfiguration.activeTemplate.items.filter(
      p =>
        p.itemTemplateTypeId === ItemTemplateType.FreeReoccuring ||
        p.itemTemplateTypeId === ItemTemplateType.FreeOnetime
    )
  }

  get currentConfiguration(): ItemTemplateConfiguration {
    return this.activeConfiguration
  }

  @Mutation
  private SET_Items(items: Array<Item>) {
    this.items.length = 0
    this.items.push(...items)
  }

  @Mutation
  private SET_SubscriptionDetails(details: SubscriptionDetails) {
    this.subscriptionDetails = details
  }

  @Mutation
  private SET_ShippingDetails(details: ShippingDetails) {
    this.shipmentDetails = details
  }

  @Mutation
  private PROCESS_SyncUnboxedToActiveTemplate(input: { surcharge: number }) {
    this.activeConfiguration.activeTemplate.items.length = 0

    this.unboxedItems.forEach(p => {
      for (let index = 0; index < p.shippingQuantity; index++) {
        this.activeConfiguration.activeTemplate.items.push({
          productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
          itemId: parseInt(p.id),
          item: p.item,
          itemTemplateTypeId: p.itemTemplateTypeId,
          referenceItemPrice: p.item!.itemAvailabilities.find(
            q => q.fulfillmentCenterId === this.shipmentDetails.fulfillmentCenterId
          )!.price,
          surcharge: 0,
          quantity: p.item!.quantity,
          shippingQuantity: 1,
          displayOrder: p.displayOrder,
          createdDate: new Date(Date.now()),
          updatedDate: new Date(Date.now()),
          id: p.id,
          subsituteItems: new Array<SubstituteItem>()
        })
      }
    })

    this.activeConfiguration.activeTemplate.surcharge = input.surcharge
  }

  @Mutation
  private SET_ActiveTemplate(details: ProductConfigurationTemplate) {
    this.activeConfiguration.activeTemplate = details
    this.activeConfiguration.surcharge = this.pricingDetails.surcharge
    this.activeConfiguration.listPrice = this.pricingDetails.listPrice
    this.activeConfiguration.discount = this.pricingDetails.discount
    this.activeConfiguration.isReadonly = this.shipmentDetails.isInProgress
    this.activeConfiguration.shippingCenter = this.shipmentDetails.fulfillmentCenterId
    this.activeConfiguration.name = `${this.subscriptionDetails.firstName}'s Next Pack`
    this.activeConfiguration.id = this.activeConfiguration.activeTemplate.productConfigurationTemplateId
    this.unboxedItems.length = 0
    if (
      this.activeConfiguration.activeTemplate.productConfigurationTemplateType ===
        ProductConfigurationTemplateType.Unboxed &&
      this.items
    ) {
      this.items.forEach(p => {
        let templateItems = this.activeConfiguration.activeTemplate.items.filter(
          q => q.itemId === parseInt(p.id) && q.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
        )
        if (templateItems.length > 0) {
          this.unboxedItems.push({
            productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
            createdDate: this.activeConfiguration.activeTemplate.createdDate,
            displayOrder: min(templateItems.map(r => r.displayOrder)) ?? 99,
            quantity: p.quantity,
            id: p.id,
            shippingQuantity: templateItems.length,
            item: p,
            itemId: parseInt(p.id),
            itemTemplateTypeId: ItemTemplateType.StandardReoccuring,
            referenceItemPrice: 0,
            subsituteItems: [],
            surcharge: 0,
            updatedDate: this.activeConfiguration.activeTemplate.updatedDate
          })
        }

        templateItems = this.activeConfiguration.activeTemplate.items.filter(
          q =>
            q.itemId === parseInt(p.id) &&
            (q.itemTemplateTypeId === ItemTemplateType.FreeReoccuring ||
              q.itemTemplateTypeId === ItemTemplateType.FreeOnetime)
        )
        templateItems.forEach(q => {
          this.unboxedItems.push({
            productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
            createdDate: this.activeConfiguration.activeTemplate.createdDate,
            displayOrder: 99,
            quantity: p.quantity,
            id: p.id,
            shippingQuantity: 1,
            item: p,
            itemId: parseInt(p.id),
            itemTemplateTypeId: q.itemTemplateTypeId,
            referenceItemPrice: 0,
            subsituteItems: [],
            surcharge: 0,
            updatedDate: this.activeConfiguration.activeTemplate.updatedDate
          })
        })
      })
      this.unboxedItems = orderBy(this.unboxedItems, p => p.displayOrder)
    }
  }

  @Mutation
  private SET_SelectNewTemplate(template: ProductConfigurationTemplate) {
    this.activeConfiguration.activeTemplate = template
    const seperatedItems: Array<ProductConfigurationTemplateItem> = []
    this.activeConfiguration.activeTemplate.items.forEach(p => {
      const items = p.shippingQuantity
      let i = 0
      for (i; i < items; i++) {
        const obj = Object.assign({}, p)
        obj.quantity = obj.quantity / obj.shippingQuantity
        obj.shippingQuantity = 1
        obj.displayOrder = obj.displayOrder + i / 100
        obj.id = obj.itemId.toString()
        seperatedItems.push(obj)
      }
    })

    this.activeConfiguration.activeTemplate.items.length = 0
    let i = 0
    sortBy(seperatedItems, p => p.displayOrder).forEach(q => {
      q.displayOrder = i++
    })
    this.activeConfiguration.activeTemplate.items.push(...sortBy(seperatedItems, p => p.displayOrder))
    this.activeConfiguration.surcharge = 0
    this.activeConfiguration.listPrice = this.pricingDetails.listPrice
    this.activeConfiguration.discount = this.pricingDetails.discount
    this.activeConfiguration.isReadonly = this.shipmentDetails.isInProgress
    this.activeConfiguration.shippingCenter = this.shipmentDetails.fulfillmentCenterId
    this.activeConfiguration.name = `${this.subscriptionDetails.firstName}'s Next Pack`
    this.activeConfiguration.id = this.activeConfiguration.activeTemplate.productConfigurationTemplateId
  }

  @Mutation
  private SET_PricingDetails(details: PricingDetails) {
    this.pricingDetails = details
  }

  @Mutation
  private SET_PackName(name: string) {
    this.packName = name
    this.unboxed = false
  }

  @Mutation
  SET_ContactId(id: string) {
    this.subscriptionDetails.contactId = id
  }

  @Mutation
  SET_NewPackSizeLight(newPackSize: PackSize) {
    this.activeConfiguration.activeTemplate.packSize = newPackSize
    this.subscriptionDetails.packSize = newPackSize
  }

  @Mutation
  SET_NewPackSize(newPackSize: PackSize) {
    if (this.subscriptionDetails.packSize === PackSize.Large && newPackSize === PackSize.Standard) {
      this.activeConfiguration.activeTemplate.maximumProductPrice =
        this.activeConfiguration.activeTemplate.maximumProductPrice / 2
      // TODO Reduce Items?
    } else if (this.subscriptionDetails.packSize === PackSize.Standard && newPackSize === PackSize.Large) {
      this.activeConfiguration.activeTemplate.maximumProductPrice =
        this.activeConfiguration.activeTemplate.maximumProductPrice * 2
      const doubleItems: Array<ProductConfigurationTemplateItem> = []
      this.activeConfiguration.activeTemplate.items.forEach(p => {
        const items = p.shippingQuantity
        let i = 0
        for (i; i < items; i++) {
          if (
            p.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
            p.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
          ) {
            const obj = Object.assign({}, p)
            obj.quantity = obj.quantity / obj.shippingQuantity
            obj.shippingQuantity = 1
            obj.displayOrder = obj.displayOrder + i + 0.000005
            obj.id = obj.itemId.toString()
            doubleItems.push(obj)
          }
        }
      })
      this.activeConfiguration.activeTemplate.items.push(...doubleItems)
      const newItems: Array<ProductConfigurationTemplateItem> = []
      newItems.push(...sortBy(this.activeConfiguration.activeTemplate.items, p => p.displayOrder))

      this.activeConfiguration.activeTemplate.items.length = 0
      let i = 0
      sortBy(newItems, p => p.displayOrder).forEach(q => {
        q.displayOrder = i++
      })
      this.activeConfiguration.activeTemplate.items.push(...newItems)
    }
    this.activeConfiguration.activeTemplate.packSize = newPackSize
    this.subscriptionDetails.packSize = newPackSize
  }

  @Mutation
  SET_FeaturedPacks(featuredPacks: CommonItemConfiguration[]) {
    this.featuredPacks.length = 0
    const configs = featuredPacks.map(p => ({
      id: p.id,
      shippingCenter: this.shipmentDetails.fulfillmentCenterId,
      name: p.name,
      description: p.description,
      activeTemplate: {
        productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
        productConfigurationTemplateType: ProductConfigurationTemplateType.Standard,
        contactId: this.subscriptionDetails.contactId,
        templateName: `Template From ${p.id}`,
        packSize: this.subscriptionDetails.packSize,
        maximumProductPrice: this.activeConfiguration.activeTemplate.maximumProductPrice,
        surcharge: 0,
        isActiveTemplate: true,
        items: p.items.map(p => ({
          productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
          itemId: parseInt(p.id),
          item: p.item,
          itemTemplateTypeId: ItemTemplateType.StandardReoccuring,
          referenceItemPrice: p.maxSwapPrice ?? 0,
          surcharge: 0,
          quantity: 0,
          shippingQuantity: p.shippingQuantity,
          displayOrder: p.displayOrder / 100,
          createdDate: new Date(Date.now()),
          updatedDate: new Date(Date.now()),
          id: p.id,
          subsituteItems: new Array<SubstituteItem>()
        })),
        createdDate: new Date(Date.now()),
        updatedDate: new Date(Date.now())
      },
      isReadonly: false,
      surcharge: 0,
      discount: 0,
      listPrice: 0
    }))

    configs.forEach(p => {
      p.activeTemplate.items.push(
        ...this.activeConfiguration.activeTemplate.items.filter(
          p =>
            p.itemTemplateTypeId === ItemTemplateType.FreeOnetime ||
            p.itemTemplateTypeId === ItemTemplateType.FreeReoccuring
        )
      )

      let i = 0
      sortBy(p.activeTemplate.items, p => p.displayOrder).forEach(q => {
        if (
          q.itemTemplateTypeId !== ItemTemplateType.FreeOnetime &&
          q.itemTemplateTypeId !== ItemTemplateType.FreeReoccuring
        ) {
          q.displayOrder = i++
        } else {
          q.id = q.itemId.toString()
          q.displayOrder = 99 + i
        }
      })
      p.activeTemplate.items = sortBy(p.activeTemplate.items, p => p.displayOrder)
    })
    this.featuredPacks.push(...configs)
  }

  @Mutation
  private SET_AttachSubsituteItemsToPreconfiguredPack(input: {
    id: string
    subsitutes: Array<{
      id: number
      subsituteItems: Array<{ subsituteItemId: number; displayOrder: number; surcharge: number }>
    }>
  }) {
    const items = this.activeConfiguration.activeTemplate.items
    input.subsitutes.forEach(p => {
      const matchingItems = items.filter(q => q.displayOrder === p.id)
      matchingItems.forEach(x => {
        const item = x
        if (item) {
          if (item.subsituteItems === undefined) {
            item.subsituteItems = []
          } else {
            item.subsituteItems.length = 0
          }
          const mappedItems = p.subsituteItems.map(r => ({
            item: this.items.find(s => s.id === r.subsituteItemId.toString()),
            id: r.subsituteItemId.toString(),
            displayOrder: r.displayOrder,
            surcharge: r.surcharge
          }))
          item.subsituteItems.push(...mappedItems)
        }
      })
    })
  }

  @Mutation
  private PROCESS_SwapItem(input: { itemIndex: number; newItemId: string; surcharge: number }) {
    const itemToSwapOut = this.activeConfiguration.activeTemplate.items[input.itemIndex]
    const itemToSwapIn = itemToSwapOut.subsituteItems.find(p => p.id === input.newItemId)?.item

    if (itemToSwapIn && itemToSwapOut.item) {
      itemToSwapOut.id = itemToSwapIn?.id
      itemToSwapOut.itemId = parseInt(itemToSwapIn?.id)
      itemToSwapOut.item = itemToSwapIn
      itemToSwapOut.surcharge = input.surcharge ? input.surcharge : 0
      itemToSwapOut.quantity = itemToSwapIn.quantity
    }
  }

  @Mutation
  private PROCESS_IncreaseItemQuantity(input: { itemId: number; item: ProductConfigurationTemplateItem }) {
    let item = this.unboxedItems.find(
      p => p.itemId === input.itemId && p.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
    )

    if (item === undefined) {
      item = JSON.parse(JSON.stringify(input.item)) as ProductConfigurationTemplateItem

      item.shippingQuantity++
      item.displayOrder =
        this.unboxedItems.filter(p => p.itemTemplateTypeId === ItemTemplateType.StandardReoccuring).length + 1
      this.unboxedItems.push(item)
      this.unboxedItems = sortBy(this.unboxedItems, p => p.displayOrder)
    } else if (item) {
      item.shippingQuantity++
      item.quantity = item.item!.quantity * item.shippingQuantity
    }
  }

  @Mutation
  private PROCESS_DecreaseItemQuantity(input: { itemId: number }) {
    const item = this.unboxedItems.find(
      p => p.itemId === input.itemId && p.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
    )

    if (item) {
      item.shippingQuantity--
      item.quantity = item.item!.quantity * item.shippingQuantity
    }
    if (item && item.shippingQuantity === 0) {
      this.unboxedItems.splice(this.unboxedItems.indexOf(item), 1)
    }
  }

  @Mutation
  private SET_EditUnboxed(value: boolean) {
    this.editingUnboxed = value
  }

  @Mutation
  private SET_Unboxed() {
    this.unboxed = true

    const freeItems = this.activeConfiguration.activeTemplate.items.filter(
      p =>
        p.itemTemplateTypeId === ItemTemplateType.FreeOnetime ||
        p.itemTemplateTypeId === ItemTemplateType.FreeReoccuring
    )

    if (
      this.activeConfiguration.activeTemplate.productConfigurationTemplateType !==
      ProductConfigurationTemplateType.Unboxed
    ) {
      this.unboxedItems.push(...freeItems)
      this.activeConfiguration.activeTemplate.productConfigurationTemplateType =
        ProductConfigurationTemplateType.Unboxed
    } else {
      this.unboxedItems.length = 0
      this.unboxedItems.push(...freeItems)
    }
  }

  @Mutation
  private SET_Classic() {
    this.unboxed = false
    this.activeConfiguration.activeTemplate.productConfigurationTemplateType = ProductConfigurationTemplateType.Standard
  }

  @Mutation
  private SET_AttachPackItems() {
    this.activeConfiguration.activeTemplate.items.forEach(p => {
      // eslint-disable-next-line no-param-reassign

      const actualItem = this.items.find(q => q.id === p.itemId.toString())
      if (actualItem) {
        p.item = actualItem
        if (p.subsituteItems) {
          p.subsituteItems.forEach(r => {
            // eslint-disable-next-line no-param-reassign
            r.item = this.items.find(r1 => r1.id === r.id)
          }, this)
        }
      }
    }, this)

    this.featuredPacks.forEach(p => {
      p.activeTemplate.items.forEach(o => {
        // eslint-disable-next-line no-param-reassign
        o.item = this.items.find(q => q.id === o.id)
        if (o.subsituteItems) {
          o.subsituteItems.forEach(r => {
            // eslint-disable-next-line no-param-reassign
            r.item = this.items.find(r1 => r1.id === r.id)
          }, this)
        }
      }, this)
    })
  }

  @Mutation
  private SET_NextPackItems(items: ProductConfigurationTemplateItem[]) {
    this.activeConfiguration.activeTemplate.items.length = 0
    this.activeConfiguration.activeTemplate.items.push(...items)
  }

  @Action
  SwapItem(input: { itemIndex: number; newItemId: string }) {
    this.context.commit('PROCESS_SwapItem', input)
  }

  @Action
  IncreaseItemQuantity(input: { itemId: number }) {
    const item = this.unboxedAddItems.find(p => p.itemId === input.itemId)
    this.context.commit('PROCESS_IncreaseItemQuantity', { itemId: input.itemId, item: item })
  }

  @Action
  DecreaseItemQuantity(input: { itemId: number }) {
    this.context.commit('PROCESS_DecreaseItemQuantity', input)
  }

  @Action
  async CustomizeWithSelectedProteins(input: { selectedProteins: Array<ProteinType> }) {
    const customPack = await api.GetQuickAccessBYOCustomPackItems(input)
    const packItems: Array<LegacyPackItem> = customPack.items
    const config = {
      id: 'NONE',
      shippingCenter: this.shipmentDetails.fulfillmentCenterId,
      name: '',
      description: '',
      activeTemplate: {
        productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
        productConfigurationTemplateType: this.isUnboxed
          ? ProductConfigurationTemplateType.Unboxed
          : ProductConfigurationTemplateType.Standard,
        contactId: this.subscriptionDetails.contactId,
        templateName: `Template From ${customPack.id}`,
        packSize: this.subscriptionDetails.packSize,
        maximumProductPrice: this.activeConfiguration.activeTemplate.maximumProductPrice,
        surcharge: 0,
        isActiveTemplate: true,
        items: packItems.map(p => ({
          productConfigurationTemplateId: this.activeConfiguration.activeTemplate.productConfigurationTemplateId,
          itemId: parseInt(p.id),
          item: this.items.find(q => q.id === p.id),
          itemTemplateTypeId: ItemTemplateType.StandardReoccuring,
          referenceItemPrice: p.maxSwapPrice ?? 0,
          surcharge: 0,
          quantity: 0,
          shippingQuantity: p.shippingQuantity,
          displayOrder: p.displayOrder / 100,
          createdDate: new Date(Date.now()),
          updatedDate: new Date(Date.now()),
          id: p.id,
          subsituteItems: new Array<SubstituteItem>()
        })),
        createdDate: new Date(Date.now()),
        updatedDate: new Date(Date.now())
      },
      isReadonly: false,
      surcharge: 0,
      discount: 0,
      listPrice: 0
    }

    config.activeTemplate.items.push(
      ...this.activeConfiguration.activeTemplate.items.filter(
        p =>
          p.itemTemplateTypeId === ItemTemplateType.FreeOnetime ||
          p.itemTemplateTypeId === ItemTemplateType.FreeReoccuring
      )
    )

    let i = 0
    sortBy(config.activeTemplate.items, p => p.displayOrder).forEach(q => {
      q.displayOrder = i++
    })

    config.activeTemplate.items = sortBy(config.activeTemplate.items, p => p.displayOrder)
    const packItemsToCustomize: Array<ProductConfigurationTemplateItem> = []

    if (this.activeConfiguration.activeTemplate.packSize === PackSize.Large) {
      const newItems: Array<ProductConfigurationTemplateItem> = []
      config.activeTemplate.items.forEach(p => {
        if (
          p.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
          p.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
        ) {
          const dupItem = Object.assign({}, p)
          p.displayOrder = p.displayOrder + 0.0001
          newItems.push(dupItem)
        }
      })

      config.activeTemplate.items.push(...newItems)

      let i = 0
      sortBy(config.activeTemplate.items, p => p.displayOrder).forEach(q => {
        q.displayOrder = i++
      })
    }
    this.context.commit(
      'SET_NextPackItems',
      orderBy(config.activeTemplate.items, p => p.displayOrder)
    )

    this.context.commit('SET_SelectNewTemplate', config.activeTemplate)

    const subs = await api.GetQuickAccessBYOSubsituteItems({
      center: this.shipmentDetails.fulfillmentCenterId,
      rangeItems: uniq(
        config.activeTemplate.items
          .filter(
            q =>
              q.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
              q.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
          )
          .map(p => {
            return { displayOrder: p.displayOrder, maxSwapPrice: p.referenceItemPrice }
          })
      ),
      proteinFilters: input.selectedProteins,
      restrictToEqualValue: false
    })

    packItemsToCustomize.push(...customPack.items)
    this.context.commit('SET_AttachSubsituteItemsToPreconfiguredPack', { id: 'NONE', subsitutes: subs })
    this.context.commit('SET_PackName', 'Build Your Own')
  }

  @Action
  async UpdateNextPackTemplate(input: { id: string; size: PackSize }) {
    if (input.id !== this.activeConfiguration.id) {
      this.activeConfiguration.activeTemplate = this.featuredPacks.find(p => p.id === input.id)!.activeTemplate
    }
    this.context.commit('SET_NewPackSize', input.size)

    if (this.isUnboxed) {
      this.context.commit('PROCESS_SyncUnboxedToActiveTemplate', { surcharge: this.totalUnboxedSurcharge })
    }
    const request = {
      fulfillmentCenterId: this.activeConfiguration.shippingCenter,
      template: JSON.parse(JSON.stringify(this.activeConfiguration.activeTemplate)) as ProductConfigurationTemplate
    }

    let displayOrder = 0
    sortBy(request.template.items, p => p.referenceItemPrice * -1).forEach(q => {
      if (q.itemTemplateTypeId === ItemTemplateType.StandardReoccuring) {
        q.displayOrder = displayOrder
        displayOrder++
      } else {
        q.displayOrder = 99
      }
      if (request.template.productConfigurationTemplateType === ProductConfigurationTemplateType.Unboxed) {
        q.surcharge = 0
      }
      q.quantity = this.items.find(p => p.id === q.itemId.toString())!.quantity
      q.item = undefined
    })

    if (
      this.isUnboxed === false &&
      request.template.productConfigurationTemplateType === ProductConfigurationTemplateType.Standard
    ) {
      request.template.surcharge = sum(request.template.items.map(p => p.surcharge * p.shippingQuantity))
    }

    request.template.packSize = input.size
    const result = await api.UpdateTemplate(request)

    this.context.commit('SET_SubscriptionDetails', result.subscriptionDetails)
    this.context.commit('SET_ShippingDetails', result.shipmentDetails)
    this.context.commit('SET_PricingDetails', result.pricingDetails)
    this.context.commit('SET_ActiveTemplate', result.activeTemplate)
  }

  @Action
  async EnableUnboxedMode() {
    this.context.commit('SET_NewPackSize', PackSize.Standard)
    this.context.commit('SET_Unboxed')
  }

  @Action
  async EnableClassicMode() {
    this.context.commit('SET_Classic')
  }

  @Action
  async CustomizePreconfiguredCustomPack(input: { id: string; size: PackSize }) {
    this.context.commit('SET_EditUnboxed', false)
    if (this.activeConfiguration.activeTemplate.productConfigurationTemplateId === input.id) {
      this.context.commit('SET_NewPackSize', input.size)

      if (
        this.activeConfiguration.activeTemplate.productConfigurationTemplateType ===
          ProductConfigurationTemplateType.Unboxed &&
        this.isUnboxed !== true
      ) {
        this.context.commit('SET_Unboxed')
      } else if (
        this.activeConfiguration.activeTemplate.productConfigurationTemplateType ===
          ProductConfigurationTemplateType.Standard &&
        this.isUnboxed
      ) {
        this.context.commit('SET_Classic')
      }

      if (this.isUnboxed) {
        this.context.commit('SET_EditUnboxed', true)
      }
    } else {
      this.context.commit('SET_Classic')
    }

    const packItems: Array<{ displayOrder: number; maxSwapPrice: number }> = []
    const proteins: Array<ProteinType> = []
    if (this.activeConfiguration.activeTemplate.productConfigurationTemplateId === input.id) {
      packItems.push(
        ...this.activeConfiguration.activeTemplate.items
          .filter(
            q =>
              q.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
              q.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
          )
          .map(p => {
            return { displayOrder: p.displayOrder, maxSwapPrice: p.referenceItemPrice }
          })
      )
      proteins.push(...uniq(this.items.map(p => p.proteinType)))
    } else {
      packItems.push(
        ...this.featuredPacks
          .find(p => p.id === input.id)!
          .activeTemplate.items.filter(
            q =>
              q.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
              q.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
          )
          .map(p => {
            return { displayOrder: p.displayOrder, maxSwapPrice: p.referenceItemPrice }
          })
      )
      proteins.push(
        ...uniq(
          this.featuredPacks
            .find(p => p.id === input.id)!
            .activeTemplate.items.map(p => (p.item ? p.item.proteinType : ProteinType.Unknown))
        )
      )

      this.context.commit('SET_PackName', this.featuredPacks.find(p => p.id === input.id)?.name)
    }
    const packItemsToCustomize: Array<ProductConfigurationTemplateItem> = []
    if (input.id !== this.activeConfiguration.activeTemplate.productConfigurationTemplateId) {
      packItemsToCustomize.push(...this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items)
      if (input.size === PackSize.Large) {
        const newItems: Array<ProductConfigurationTemplateItem> = []
        packItemsToCustomize.forEach(p => {
          if (
            p.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
            p.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
          ) {
            const dupItem = Object.assign({}, p)
            p.displayOrder = p.displayOrder + 0.0001
            newItems.push(dupItem)
          }
        })
        packItemsToCustomize.push(...newItems)
      }
      this.context.commit(
        'SET_NextPackItems',
        orderBy(packItemsToCustomize, p => p.displayOrder)
      )
      this.context.commit('SET_NewPackSizeLight', input.size)
    }

    const subs = await api.GetQuickAccessBYOSubsituteItems({
      center: this.shipmentDetails.fulfillmentCenterId,
      rangeItems: uniq(packItems),
      proteinFilters: proteins,
      restrictToEqualValue: true
    })

    this.context.commit('SET_AttachSubsituteItemsToPreconfiguredPack', {
      id: input.id,
      subsitutes: sortBy(subs, p => p.displayOrder)
    })
  }

  /* Replace with new template update
  @Action
  async UpdateNextPackWithCustomizedItems() {
    const packItems: Array<LegacyPackItem> = []
    const proteins: Array<ProteinType> = []
    packItems.push(...this.activeTemplate.items)
    proteins.push(...this.customizedPackProteins)
    await api.UpdatePack({
      contactId: this.,
      packSize: this.newPackSize,
      items: packItems,
      proteins
    })
  }

  @Action
  async UpdateNextPackWithPreconfiguredItems(input: { id: string; size: PackSize }) {
    const packItems: Array<LegacyPackItem> = []
    const proteins: Array<ProteinType> = []

    this.context.commit('SET_NewPackSize', input.size)
    if (this.nextPack.id === input.id) {
      packItems.push(...this.nextPack.items)
      proteins.push(...this.nextPack.proteins)
    } else {
      packItems.push(...this.featuredPacks.find(p => p.id === input.id)!.items)
      proteins.push(...this.featuredPacks.find(p => p.id === input.id)!.proteins)
      this.context.commit('SET_PackName', this.featuredPacks.find(p => p.id === input.id)?.name)
    }

    if (
      input.size === PackSize.Large &&
      this.subscriptionConfiguration.packSize === PackSize.Standard &&
      this.nextPack.id === input.id
    ) {
      packItems.push(...packItems)
    }

    if (input.size === PackSize.Large && this.nextPack.id !== input.id) {
      packItems.push(...packItems)
    }

    packItems.push(...this.freeItems)
    await api.UpdatePack({
      contactId: this.contactId,
      packSize: this.newPackSize,
      items: packItems,
      proteins
    })
  }
  */

  @Action
  async Startup(contactId: string) {
    const items = GetItems()
    this.context.commit('SET_ContactId', contactId)
    const quickAccessBYOConfiguration = api.GetCurrentTemplate({ contactId })
    const featuredPacks = api.GetQuickAccessBYOFeautedPacks()
    const results = await Promise.all([items, quickAccessBYOConfiguration, featuredPacks])
    this.context.commit('SET_Items', results[0])
    this.context.commit('SET_SubscriptionDetails', results[1].subscriptionDetails)
    this.context.commit('SET_ShippingDetails', results[1].shipmentDetails)
    this.context.commit('SET_PricingDetails', results[1].pricingDetails)
    this.context.commit('SET_ActiveTemplate', results[1].activeTemplate)
    this.context.commit('SET_FeaturedPacks', results[2])
    this.context.commit('SET_AttachPackItems')
  }
}
