












































import { Component, Vue, Prop } from 'vue-property-decorator'
import ItemReplacementCard from '@/components/common/ItemSelectorList/ItemReplacementCard/index.vue'
import { PackItem, ProteinType } from '@/modules/common'
import { sortBy, uniq } from 'lodash'

@Component({ components: { ItemReplacementCard } })
export default class ItemSwapper extends Vue {
  @Prop({ type: Object as () => PackItem })
  private PackItem!: PackItem;

  get ItemName() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.name
    }
    return null
  }

  get ItemQuantity() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.quantity
    }
    return null
  }

  get ItemDescription() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.marketingDescription
    }
    return null
  }

  get ItemShortDescription() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.marketingDescriptionShort
    }
    return null
  }

  get ItemSize() {
    if (this.PackItem && this.PackItem.item) {
      return this.PackItem.item.size
    }
    return null
  }

  get proteins() {
    if (this.PackItem && this.PackItem.subsituteItems) {
      return sortBy(uniq(this.PackItem.subsituteItems.filter((p) => p.item &&
    this.PackItem &&
    this.PackItem.item &&
    p.item.id !== this.PackItem.item.id).map((p) => p.item!.proteinType)), (p) => p)
    }
    return []
  }

  get thumbnail() {
    if (this.PackItem) {
      return `https://assets.steakchop.com/product-thumbnails/${this.PackItem.id}.jpg`
    }
    return undefined
  }

  get imageHeight() {
    let height = 0

    if (this.$vuetify.breakpoint.smAndDown) {
      return undefined
    }
    height = 160

    return `${height}px`
  }

  filterProtein(protein: ProteinType) {
    this.$emit('filter-protein', protein)
  }

  get imageWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return undefined
    }
    return `${(this.$vuetify.breakpoint.width * 0.95) / 2}px`
  }
}
