

















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Frequency, ItemTemplateConfiguration, PackSize } from '@/modules/common'
import sum from 'lodash/sum'
import sortBy from 'lodash/sortBy'
import { groupBy, map, uniq } from 'lodash'
import { ItemTemplateType, ProductConfigurationTemplateItem, ProductConfigurationTemplateType } from '@/modules/byo'

@Component({ components: {} })
export default class PackCard extends Vue {
  @Prop({ type: Object as () => ItemTemplateConfiguration })
  private CommonItemConfiguration!: ItemTemplateConfiguration

  @Prop()
  private supressImage!: boolean | undefined

  @Prop()
  private readonly!: boolean | undefined

  @Prop()
  private packName!: string | undefined

  @Prop()
  private confirmMode!: boolean | undefined

  @Prop()
  private selectedPackId!: string | undefined

  @Prop()
  private packSize!: PackSize | undefined

  @Prop()
  private valueIcon!: string | undefined

  @Prop()
  private valueProp!: string | undefined

  @Prop()
  private upsellMode!: boolean | undefined

  @Prop()
  private suppressPricing!: boolean | undefined

  @Prop()
  private discount: number | undefined

  @Prop()
  private priceVersion: string | undefined

  @Prop()
  private frequency: Frequency | undefined

  @Prop({ default: false })
  private forceGrouping!: boolean

  @Prop()
  private elevation!: number

  private firstFreeItem: number | undefined = undefined

  @Watch('packSize')
  onPackSizeChange(newValue: PackSize) {
    if (this.CommonItemConfiguration.id === this.selectedPackId && this.confirmMode === false) {
      this.Size = newValue === PackSize.Large
    }
  }

  get proteins() {
    if (this.CommonItemConfiguration.activeTemplate.items) {
      return uniq(this.CommonItemConfiguration.activeTemplate.items.map((p) => (p.item ? p.item.proteinType : 0)))
    }
    return []
  }

  get canUpsell() {
    if (this.CommonItemConfiguration.activeTemplate.productConfigurationTemplateType) {
      if (
        this.CommonItemConfiguration.activeTemplate.productConfigurationTemplateType ===
        ProductConfigurationTemplateType.Standard
      ) {
        return this.upsellMode
      } else {
        return false
      }
    }
    return this.upsellMode
  }

  get packElevation() {
    if (this.elevation !== undefined) {
      return this.elevation
    }
    return 5
  }

  get isTemplatedBased() {
    return true
  }

  get multipleFree() {
    return this.CommonItemConfiguration.activeTemplate.items.filter((p) => this.isFreeItem(p.itemTemplateTypeId))
      .length > 1
      ? 's'
      : ''
  }

  get canCustomize() {
    if (this.CommonItemConfiguration.activeTemplate.productConfigurationTemplateType) {
      if (
        this.readonly ||
        this.CommonItemConfiguration.activeTemplate.productConfigurationTemplateType ===
          ProductConfigurationTemplateType.CustomCurated
      ) {
        return false
      }
      return true
    }

    if (this.readonly === true || this.confirmMode === true) {
      return false
    }
    return true
  }

  get ValueIcon() {
    if (this.valueIcon && this.valueIcon !== '') {
      return this.valueIcon
    }
    return null
  }

  get ValueProp() {
    if (this.valueProp && this.valueProp !== '') {
      return this.valueProp
    }
    return null
  }

  size = false

  itemIndex = 0

  get sizeVerbose() {
    if (this.Size) {
      return 'Perfect for families with 4-5 people!'
    }
    return 'Great for families with 1-4 people!'
  }

  get Size() {
    if (this.forceGrouping) {
      return true
    }
    return this.size
  }

  set Size(value: boolean) {
    this.size = value
  }

  get doubleValue() {
    return this.forceGrouping === true ? 1 : 2
  }

  get finalPrice() {
    return `$${this.calcPrice}`
  }

  get calcPrice() {
    return (
      (this.CommonItemConfiguration.listPrice -
        this.CommonItemConfiguration.discount +
        this.CommonItemConfiguration.surcharge) *
        (this.size && this.confirmMode === false ? 2 : 1) -
      (this.size && this.confirmMode === false ? 10 : 0)
    )
  }

  get nonDiscountedPrice() {
    let price = 149.99
    if (this.priceVersion === 'V2') {
      price += 10
    }
    if (this.priceVersion === 'V3') {
      price += 20
    }
    if (this.Size && this.CommonItemConfiguration.activeTemplate.packSize === PackSize.Large) {
      price = price * 2 - 10
    }
    return price
  }

  get discountedPrice() {
    if (this.discount) {
      return this.nonDiscountedPrice - this.discount
    }
    return this.nonDiscountedPrice
  }

  get groupedItems() {
    if (this.CommonItemConfiguration.activeTemplate.items.length > 0) {
      const g = groupBy(this.CommonItemConfiguration.activeTemplate.items, (p) => p.itemId + '.' + p.itemTemplateTypeId)
      const result = map(g, (items) => ({
        item: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          quantity: items[0].item ? items[0].item.quantity * items.length : 0,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name: items[0].item?.name,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          size: items[0].item?.size
        },
        id: items[0].item?.id,
        isFreeItem:
          items[0].itemTemplateTypeId === ItemTemplateType.FreeOnetime ||
          items[0].itemTemplateTypeId === ItemTemplateType.FreeReoccuring,
        displayOrder: items[0].displayOrder,
        ItemTemplateType: items[0].itemTemplateTypeId,
        canDouble:
          items[0].itemTemplateTypeId === ItemTemplateType.StandardReoccuring ||
          items[0].itemTemplateTypeId === ItemTemplateType.StandardOnetime
      }))

      return sortBy(
        result.map((q) => ({
          item: q.item,
          id: q.id,
          isFreeItem: q.isFreeItem,
          displayOrder: q.displayOrder,
          canDouble: q.canDouble,
          itemTemplateType: q.ItemTemplateType
        })),
        (x) => x.displayOrder
      )
    }
    return []
  }

  get items(): ProductConfigurationTemplateItem[] {
    if (this.CommonItemConfiguration.activeTemplate.items.length > 0) {
      const theItems = sortBy(this.CommonItemConfiguration.activeTemplate.items, (p) => p.displayOrder)

      return theItems
    }

    return []
  }

  get pricePerFive() {
    if (this.CommonItemConfiguration.activeTemplate.items) {
      let totalServings = sum(
        this.CommonItemConfiguration.activeTemplate.items.map((p) =>
          p.item
            ? p.item.calcWeight *
              p.shippingQuantity *
              (this.size &&
              this.confirmMode === false &&
              p.itemTemplateTypeId !== ItemTemplateType.FreeReoccuring &&
              p.itemTemplateTypeId !== ItemTemplateType.FreeOnetime
                ? 2
                : 1)
            : 0
        )
      )
      totalServings = totalServings / 5
      return (this.calcPrice / totalServings).toFixed(2)
    } else {
      return (0).toFixed(2)
    }
  }

  isFreeItem(templateType: ItemTemplateType) {
    return templateType === ItemTemplateType.FreeReoccuring || templateType === ItemTemplateType.FreeOnetime
  }

  isFirstFreeItem(index: number) {
    if (!this.firstFreeItem) {
      this.firstFreeItem = -1
      this.groupedItems.forEach((p) => {
        if (this.firstFreeItem === -1 && this.isFreeItem(p.itemTemplateType)) {
          this.firstFreeItem = this.groupedItems.indexOf(p)
        }
      })
    }
    return index === this.firstFreeItem
  }

  selectCommonItemConfiguration(input: { id: string; personalize: boolean; size: PackSize; frequency: Frequency }) {
    this.$emit('common-item-configuration-selected', input)
  }

  select(id: string) {
    this.$emit('common-item-configuration-selected', {
      id,
      frequency: this.frequency,
      personalize: false,
      size: this.Size ? PackSize.Large : PackSize.Standard
    })
  }

  personalize(id: string) {
    this.$emit('common-item-configuration-selected', {
      id,
      frequency: this.frequency,
      size: this.Size ? PackSize.Large : PackSize.Standard,
      personalize: true
    })
  }

  get thumbnail() {
    return `https://assets.steakchop.com/pack-thumbnails/${this.CommonItemConfiguration.id}.jpg`
  }

  mounted() {
    if (this.packSize && this.packSize === PackSize.Large) {
      this.Size = true
    }
  }
}
