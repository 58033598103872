






































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ItemReplacementCard from '@/components/common/ItemSelectorList/ItemReplacementCard/index.vue'
import { SubstituteItem } from '@/modules/common'
import { ItemTemplateType, ProductConfigurationTemplateItem } from '@/modules/byo/index'
import { rounder } from '@/store/modules/quick-access'
@Component({ components: { ItemReplacementCard } })
export default class ItemCard extends Vue {
  @Prop({ type: Object as () => ProductConfigurationTemplateItem })
  private PackItem!: ProductConfigurationTemplateItem

  @Prop()
  private ItemIndex!: number

  @Prop()
  private moneyLeft!: number

  @Prop()
  private SwapIndex!: number

  @Prop()
  private showSwapper!: boolean

  @Prop()
  private unboxed!: boolean

  @Prop()
  private roundedPrice!: number

  @Prop()
  private standardPrice!: number

  pulsing = false

  shippingQuantity = -1

  @Watch('showSwapper', { immediate: true })
  onShowSwapperChanged(newValue: boolean) {
    if (newValue === true) {
      this.pulsing = false
    }
  }

  @Watch('PackItem.id', { immediate: true, deep: true })
  onPackItemChanged(newValue: string, oldValue: string) {
    if (oldValue !== undefined && !this.showSwapper) {
      this.pulsing = true
    }
  }

  @Watch('PackItem.shippingQuantity', { immediate: true, deep: true })
  onPackItemShippingQuantityChange(newValue: number) {
    this.shippingQuantity = newValue
  }

  get actualShippingQuantity() {
    if (this.shippingQuantity !== -1) return this.shippingQuantity
    return this.PackItem.shippingQuantity
  }

  get isNotUnboxed() {
    return this.unboxed === false
  }

  get isUnboxed() {
    return this.unboxed === true && this.isFreeItem === false
  }

  get canFit() {
    return this.standardPrice <= this.moneyLeft
  }

  get upgradeCharge() {
    if (this.canFit) {
      return 0
    }
    if (this.moneyLeft > 0) {
      return rounder((this.standardPrice - this.moneyLeft) * 1.055, 0.25)
    }
    return this.roundedPrice
  }

  get imageHeight() {
    let height = 0

    if (this.$vuetify.breakpoint.smAndDown) {
      return undefined
    }
    height = 160

    return `${height}px`
  }

  get imageWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return undefined
    }
    return `${(this.$vuetify.breakpoint.width * 0.95) / 2}px`
  }

  get showReplacements() {
    return this.SwapIndex === this.ItemIndex
  }

  get isFreeItem() {
    return (
      this.PackItem.itemTemplateTypeId === ItemTemplateType.FreeOnetime ||
      this.PackItem.itemTemplateTypeId === ItemTemplateType.FreeReoccuring
    )
  }

  get surcharge() {
    return this.PackItem.surcharge
  }

  get thumbnail() {
    if (this.PackItem.id) {
      return `https://assets.steakchop.com/product-thumbnails/${this.PackItem.id}.jpg`
    }
    return `https://assets.steakchop.com/product-thumbnails/${this.PackItem.item?.id}.jpg`
  }

  get SubstituteItems(): Array<SubstituteItem> {
    if (
      this.PackItem.subsituteItems &&
      (this.PackItem.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
        this.PackItem.itemTemplateTypeId === ItemTemplateType.StandardReoccuring)
    ) {
      return this.PackItem.subsituteItems
    }
    return []
  }

  increaseShippingQuantity() {
    if (this.shippingQuantity === -1) {
      this.shippingQuantity = this.PackItem.shippingQuantity
    }
    this.shippingQuantity++
    this.$emit('increase-item-quantity', { itemId: this.PackItem.itemId })
  }

  decreaseShippingQuantity() {
    if (this.shippingQuantity === -1) {
      this.shippingQuantity = this.PackItem.shippingQuantity
    }
    this.shippingQuantity--
    this.$emit('decrease-item-quantity', { itemId: this.PackItem.itemId })
  }

  substituteItem(input: { itemIndex: number; newItemId: string; surcharge: number }) {
    this.$emit('substitute-item', input)
  }
}
