









































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ItemCard from '@/components/common/ItemSelectorList/ItemCard/index.vue'
import ItemReplacementCard from '@/components/common/ItemSelectorList/ItemReplacementCard/index.vue'
import ItemSwapperList from '@/components/common/ItemSelectorList/ItemSwapperList/index.vue'
import ItemSwapperHeader from '@/components/common/ItemSelectorList/ItemSwapperHeader/index.vue'
import { PackItem, ProteinType, SubstituteItem } from '@/modules/common'
import { ItemTemplateType, ProductConfigurationTemplateItem } from '@/modules/byo'
import { orderBy } from 'lodash'
import CommonModule from '@/store/modules/common'
import { getModule } from 'vuex-module-decorators'
import QuickAccessModule from '@/store/modules/quick-access'

@Component({
  components: {
    ItemCard,
    ItemReplacementCard,
    ItemSwapperList,
    ItemSwapperHeader
  }
})
export default class ItemSelectorList extends Vue {
  subsituteItems: Array<SubstituteItem> = []

  substituteIndex = -1

  confirmSwap = false

  swappedItemName = ''

  swappedItemId = ''

  swappedItemIndex = -1

  enableSwapping = true

  swappedItemSurcharge = 0

  addingItem = false

  proteinFilter = ProteinType.Unknown

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  quickAccessModule: QuickAccessModule = getModule(QuickAccessModule, this.$store)

  @Prop({ type: Array as () => Array<SubstituteItem> })
  private unboxedAddItems: Array<ProductConfigurationTemplateItem> | undefined

  @Prop()
  private startOverRoute!: string | undefined

  @Prop()
  private moneyLeft!: number

  @Prop()
  private unlimitedSwapping!: boolean | undefined

  @Prop({ type: Array as () => Array<ProteinType> })
  private packProteins: Array<ProteinType> | undefined

  @Prop({ type: Array as () => Array<PackItem> })
  private packItems: Array<ProductConfigurationTemplateItem> | undefined

  @Prop()
  private packName!: string | undefined

  @Prop()
  private progress!: number

  @Watch('progress')
  private onProgess(oldValue: number, newValue: number) {
    if (oldValue > 0 && newValue === 0) {
      this.addItem()
    }
  }

  @Prop()
  private unboxedProgessMessage!: string

  @Prop()
  private minPurchasePrice!: string

  @Prop()
  private currentPurchasePrice!: string

  @Prop()
  private currentSurcharge!: string

  @Prop()
  private currentDiscount!: string

  @Prop()
  private isUnboxed!: boolean

  private eligibleItemsToAdd: Array<ProductConfigurationTemplateItem> = []

  review() {
    this.addingItem = false
  }

  applyProteinFilter(protein: ProteinType) {
    this.proteinFilter = protein
  }

  preSubstitute: { itemIndex: number; newItemId: string } = {
    itemIndex: -1,
    newItemId: ''
  }

  roundedPrice(id: string) {
    if (this.isUnboxed) {
      return this.quickAccessModule.roundedItemPrice(id)
    }
    return 0
  }

  standardPrice(id: string) {
    if (this.isUnboxed) {
      return this.quickAccessModule.standardItemPrice(id)
    }
    return 0
  }

  get progressColor() {
    if (this.progress <= 80) return 'green'
    if (this.progress < 100) return 'accent'
    return 'primary'
  }

  get swapper() {
    return this.confirmSwap
  }

  get selectedProtein() {
    if (this.isUnboxed) {
      if (this.proteinFilter === ProteinType.Unknown && this.packProteins && this.packProteins.length > 0) {
        return this.packProteins[0]
      }
    }
    return this.proteinFilter
  }

  get itemsToCustomize() {
    if (this.addingItem) {
      return this.eligibleItemsToAdd.filter((p) => p.item?.proteinType === this.selectedProtein)
    }
    return this.packItems
  }

  @Watch('swapper')
  onEnabledSwapping(newValue: boolean) {
    if (newValue === false) {
      this.$emit('substitute-item', {
        itemIndex: this.swappedItemIndex,
        newItemId: this.swappedItemId,
        surcharge: this.swappedItemSurcharge
      })

      this.swappedItemName = ''
      this.swappedItemId = ''
      this.swappedItemIndex = -1
      this.swappedItemSurcharge = 0
      this.applyProteinFilter(ProteinType.Unknown)
      if (this.unlimitedSwapping && newValue === false) {
        this.enableSwapping = true
      }
    }
  }

  get listHeight() {
    if (this.$vuetify.breakpoint.xs) {
      if (this.isUnboxed) {
        return 'max-height:65vh'
      }
      return 'max-height:70vh'
    }
    return ''
  }

  get swapCongrats() {
    // eslint-disable-next-line radix
    switch (Number.parseInt(this.swappedItemId) % 10) {
      case 0:
        return 'One of our favorites!'
      case 1:
        return 'Great call!'
      case 2:
        return 'What a swap!'
      case 3:
        return 'Looking like the perfect pack!'
      case 4:
        return 'Delicious decision!'
      case 5:
        return 'One word- Tasty!'
      case 6:
        return 'One word- Tasty!'
      case 7:
        return "You're making me hungry!"
      case 8:
        return 'Impressive choice!'
      case 9:
        return 'We love that swap!'
      default:
        return 'Nice swap!'
    }
  }

  get PackItems(): Array<PackItem> {
    if (this.packItems) {
      return this.packItems
    }
    return []
  }

  get swapperMaxHeight() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 'undefined'
    }
    return '84.2vh'
  }

  // eslint-disable-next-line class-methods-use-this
  get IsCommonPackConfiguration() {
    return false
  }

  get SelectedSubstituteItem() {
    if (this.substituteIndex !== -1) {
      return this.PackItems[this.substituteIndex]
    }
    return null
  }

  processBack() {
    if (this.startOverRoute) {
      this.$router.push(this.startOverRoute)
    }
  }

  addItem() {
    this.eligibleItemsToAdd = JSON.parse(
      JSON.stringify(
        orderBy(
          this.packItems!.concat(this.unboxedAddItems!).filter(
            (p) =>
              p.itemTemplateTypeId !== ItemTemplateType.FreeReoccuring &&
              p.itemTemplateTypeId !== ItemTemplateType.StandardOnetime
          ),
          (p) => p.displayOrder
        )
      )
    ) as Array<ProductConfigurationTemplateItem>
    this.addingItem = true
  }

  cancel() {
    this.substituteIndex = -1
    this.preSubstitute.newItemId = ''
    this.preSubstitute.itemIndex = -1
    this.$emit('cancel-customization')
  }

  increaseItemQuantity(input: { itemId: number }) {
    this.$emit('increase-item-quantity', input)
  }

  decreaseItemQuantity(input: { itemId: number }) {
    this.$emit('decrease-item-quantity', input)
  }

  showSubstitutes(itemIndex: number) {
    if (itemIndex === this.substituteIndex) {
      this.substituteIndex = -1
    } else {
      this.substituteIndex = itemIndex
      this.applyProteinFilter(this.packItems![itemIndex].item!.proteinType)
    }
  }

  preSubstituteItem(input: { itemIndex: number; newItemId: string }) {
    this.preSubstitute = input
  }

  substituteItem(input: { itemIndex: number; newItemId: string; surcharge: number }) {
    this.substituteIndex = -1
    const theItem = this.packItems![input.itemIndex].subsituteItems.filter((p) => p.id === input.newItemId)[0].item

    if (theItem) {
      this.swappedItemId = theItem.id
      this.swappedItemName = theItem.name
      this.swappedItemIndex = input.itemIndex
      this.swappedItemSurcharge = input.surcharge
      this.confirmSwap = true
      this.enableSwapping = false
      this.preSubstitute.newItemId = ''
      this.preSubstitute.itemIndex = -1
    }
  }

  async mounted() {
    if (
      this.itemsToCustomize &&
      this.itemsToCustomize.filter(
        (p) =>
          p.itemTemplateTypeId !== ItemTemplateType.FreeReoccuring &&
          p.itemTemplateTypeId !== ItemTemplateType.FreeOnetime
      ).length === 0 &&
      this.isUnboxed
    ) {
      this.addItem()
    }
    if (this.isUnboxed) {
      await this.commonModule.SetQuickActionTitle('SteakChop - Unlimited')
    } else if (this.packName) {
      await this.commonModule.SetQuickActionTitle(`Customizing ${this.packName}`)
    } else {
      await this.commonModule.SetQuickActionTitle('Customizing your Pack')
    }
  }
}
