
























import {
  Component, Vue, Prop
} from 'vue-property-decorator'
import { ProteinType } from '@/modules/common'

@Component
export default class ProteinButton extends Vue {
  @Prop()
  ProteinType!: ProteinType;

  wasSet = false;

  get iconSize() {
    if (this.$vuetify.breakpoint.smAndUp) { return 78 }
    return `${(this.$vuetify.breakpoint.height * 0.1084840055632823)}px`
  }

  get outlineSize() {
    if (this.$vuetify.breakpoint.smAndUp) return '120px'
    return `${(this.$vuetify.breakpoint.height * 0.1668984700973574)}px`
  }

  get ProteinName() {
    return ProteinType[this.ProteinType]
  }

  get checked() {
    return this.isProteinSelected
  }

  set checked(value) {
    if (this.wasSet) {
      this.$emit('toggle-protein', this.ProteinType)
    }
    this.wasSet = true
  }

  @Prop({ type: Array as () => Array<ProteinType> })
  SelectedProteins!: Array<ProteinType>;

  get isProteinSelected() {
    if (this.SelectedProteins) {
      const index = this.SelectedProteins.indexOf(this.ProteinType)
      if (index > -1) {
        return true
      }
    }
    return false
  }

  proteinCSS(): string {
    if (this.isProteinSelected) {
      return 'selected'
    }

    return 'unselected'
  }

  mounted() {
    this.checked = this.isProteinSelected
  }
}
